import './App.css';
import React from "react";
import {
  HashRouter,
  Route,Switch
} from "react-router-dom";
import About from './About.jsx';
import Reading from './Reading.jsx'
import Home from './Home.jsx'
import './color.css'
import Gallery from './Gallery.jsx'
import './jquery.fancybox.min.css'
import Listening from './Listening.jsx'
import Speaking from './Speaking.jsx'
import Writing from './Writing.jsx'
import Contact from './Contact.jsx'
import Packages from './Packages.jsx'
import './responsive.css'
import './shortcodes.css'
import './slick.css'
import './style.css'
import './typography.css'
import './widget.css'
import './color.css'
import Testimonials from './Testimonials.jsx'

import * as ReactBootstrap from "react-bootstrap";
export default function App() {
  return (
    
      <div>
      <div class="top-bar">
        <div class="col-md-12" style={{background:"#054d9a"}}>
            <div class="row">
                <div class="col-md-8">
                    <ul class="list-inline" style={{paddingRight:"20px", fontSize: "14px",paddingTop: "15px",marginLeft:"71px",paddingBottom: "13px"}}>
                        <li>
                            <div class="unit unit-spacing-xs align-items-center">

                                <div class="unit-body"><a href="tel:+61 (3) 7014 6424" style={{color: "white!important"}}> <i class="fa fa-phone"></i> +61 (3) 7014 6424</a></div>
                            </div>
                        </li>
                        <li>
                            <div class="unit unit-spacing-xs align-items-center">
                                <div class="unit-body"><a href="mailto:info@aecglobal.com.au" style={{color: "white!important"}} ><i class="fa fa-envelope"></i>   info@aecglobal.com.au</a></div>
                            </div>
                        </li>
                        <li>
                            <div class="unit unit-spacing-xs align-items-center">
                                <div class="unit-body"><a href="#" style={{color: "white!important"}}><i class="fa fa-map-marker"></i> Suite 10, Level 7, 2 Queen Street, VIC Melbourne 3000.</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <ul class="iq_footer_social1" style={{right: "191px",position: "absolute",paddingTop:"7px",fontSize:"0"}}>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/AECPTE/"><i class="fa fa-facebook"></i></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/aec_pte_academy/?igshid=mc29i900n7vh"><i class="fa fa-instagram"></i></a>
                        </li>
                      
                        <li>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=+61481840167"><i class="fa fa-whatsapp"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <HashRouter>
   
    <ReactBootstrap.Navbar bg="light" expand="lg">
  <ReactBootstrap.Navbar.Brand href="#home"><img src={process.env.PUBLIC_URL+"./images/logo1.png"} alt="LOGO" style={{width:"240px"}}></img></ReactBootstrap.Navbar.Brand>
  <ReactBootstrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
  <ReactBootstrap.Navbar.Collapse id="basic-navbar-nav">
    <ReactBootstrap.Nav className="mr-auto">
      <ReactBootstrap.Nav.Link href="#/Home">HOME</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/About">ABOUT US</ReactBootstrap.Nav.Link>
      <ReactBootstrap.NavDropdown title="PTE ACADEMIC" id="basic-nav-dropdown">
        
        <ReactBootstrap.NavDropdown.Divider />
        <ReactBootstrap.NavDropdown.Item href="#/Speaking">SPEAKING </ReactBootstrap.NavDropdown.Item>
        <ReactBootstrap.NavDropdown.Item href="#/Writing">WRITING</ReactBootstrap.NavDropdown.Item>
        <ReactBootstrap.NavDropdown.Item href="#/Reading">READING</ReactBootstrap.NavDropdown.Item>
        <ReactBootstrap.NavDropdown.Item href="#/Listening">LISTENING</ReactBootstrap.NavDropdown.Item>

      </ReactBootstrap.NavDropdown>
      <ReactBootstrap.Nav.Link href="#/Packages">PACKAGES</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/Gallery">GALLERY</ReactBootstrap.Nav.Link>
      <ReactBootstrap.Nav.Link href="#/Contact-us">CONTACT US</ReactBootstrap.Nav.Link>
      <ReactBootstrap.NavDropdown title="LOG IN" id="basic-nav-dropdown1">
        
        <ReactBootstrap.NavDropdown.Divider />
        <ReactBootstrap.NavDropdown.Item href="https://aecpteacademy.79score.com/institute/student" target="_blank" rel="nofollow">Student Login </ReactBootstrap.NavDropdown.Item>
        <ReactBootstrap.NavDropdown.Item href="https://aecpteacademy.79score.com/institute" target="_blank" rel="nofollow">Teacher Login</ReactBootstrap.NavDropdown.Item>
       

      </ReactBootstrap.NavDropdown>
    </ReactBootstrap.Nav>
   
  </ReactBootstrap.Navbar.Collapse>
</ReactBootstrap.Navbar>

  
<Route 
          exact 
          path="/" 
          component={Home}>
       </Route>


<Route path="/Home" component={Home}></Route>
<Route path="/About" component={About}></Route>
<Route path="/Listening" component={Listening}></Route>
<Route path="/Speaking" component={Speaking}></Route>
<Route path="/Reading" component={Reading}></Route>
<Route path="/Writing" component={Writing}></Route>
<Route path="/Packages" component={Packages}></Route>
<Route path="/Contact-us" component={Contact}></Route>
<Route path="/Gallery" component={Gallery}></Route>
<Route path="/Testimonials" component={Testimonials}></Route>


        
     
        </HashRouter>
      
      </div>
    
  );
}


