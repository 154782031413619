import React from 'react';
import Bottom from './Bottom.jsx'
function Testimonials(){
    return(
        <div>
            
<div class="iner_banner course-single">
    <div class="container">
        <h5>Testimonials</h5>
        <div class="banner_iner_capstion">
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Testimonials</a></li>
            </ul>
        </div>
    </div>
</div>

<section class="ulockd-contact-page">
    <div class="why container">
        <div class="row">
            <div class="text-center">
                <div class="row" style={{paddingTop:"66px"}}>
                    <div class="col-md-12" style={{paddingBottom:"40px"}}>
                    <div class="col-md-6">
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Franjit.palaniappan%2Fposts%2F110295234016572&show_text=true&width=552&height=213&appId" width="552" height="213" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>                    </div>
                    <div class="col-md-6">
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffarhad.ali.7545%2Fposts%2F2722126874684900&show_text=true&width=552&height=244&appId" width="552" height="244" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>                    </div>
                    </div>
                        <div class="col-md-12" style={{paddingBottom:"40px"}}>
                            <div class="col-md-6" style={{padding:"0px"}}>
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffaizan.jamil.5%2Fposts%2F10213514796783078&show_text=true&width=552&height=200&appId" width="552" height="200" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                            </div>
                            <div class="col-md-6" style={{padding:"0px"}}>
                                <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fankit.aggarwal.39545%2Fposts%2F2612914685473535&show_text=true&width=552&height=187&appId" width="552" height="187" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                            </div>
                            </div>
                    <div class="col-md-12" style={{paddingBottom:"40px"}}>
                        <div class="col-md-6" style={{padding:"0px"}}>
                            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsomzz.teerada%2Fposts%2F2704996739550686&show_text=true&width=552&height=219&appId" width="100%" height="350" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                        </div>
                        <div class="col-md-6" style={{padding:"0px"}}>
                            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnawaz.cr7%2Fposts%2F2764034806963235&show_text=true&width=552&height=232&appId" width="100%" height="250" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                            <div class="col-md-12" style={{paddingBottom:"40px"}}>
                                <div class="col-md-6" style={{padding:"0px"}}>
                                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fshaikhaliz%2Fposts%2F10158594625733514&show_text=true&width=552&height=232&appId" width="552" height="232" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                                </div>
                                <div class="col-md-6" style={{padding:"0px"}}>
                                    <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fharoon.javaid.5%2Fposts%2F2882894648474648&show_text=true&width=552&height=282&appId" width="552" height="282" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                                </div>
                              
                            </div>
            </div>
        </div>

    </div>
        </div>
</section>
            <Bottom></Bottom>
        </div>
    )
}
export default Testimonials;