import react from 'react';
import Bottom from './Bottom'

function About() {
    return (
        <div>
            <title>About</title>
       <div className="iner_banner">
    <div className="container">
        <h5>About Us</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">About Us</a></li>
            </ul>
        </div>
    </div>
</div>

<div className="iq_content_wrap">
    <section className="coures_single_page">
    
        <div className="about-us-section container">
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="text">
                        <div className="iq_heading_1 text-left">
                            <h4>About AEC<span> PTE Academy</span></h4>
                        </div>
                        <p>
                            AEC PTE is a trusted PTE institute in Melbourne that helps you in acing your PTE examination preparation with a good score. We offer weekly mock tests and free study plans to students so that they can prepare for the exam and perform at their level best. Our PTE institute offers both online and offline PTE classes to the students who are eager to study abroad and achieve their big dreams. Having a squad of professional experts and coaches, we offer the right guidance to each student who seeks consultation from us.
                           <br />
                             At AEC PTE Academy, we have a good reputation in the market for delivering quality PTE training the candidates consulting to us. Our establishment has helped many students to realize their dream of scoring 79+ in the most difficult English proficiency test such as PTE. We prepare students for PTE utilizing the best strategies and coaching methodologies in the best possible manner.
</p>                 
                    </div>
                </div>
                <div className="col-md-6 col-sm-6">
                    <div className="thumb">
                            <img src={process.env.PUBLIC_URL+"extra-images/about_us.jpg"} alt="about_us"></img>
                    </div>
                </div>
            </div>
            <div className="col-md-12" style={{padding:"0"}}>
                <p>AEC PTE Academy predicts an outlook in which abroad study is not a dream that can’t come true. We aim to render transparent and reliable personalized PTE programs for the bright future of every international student enrolling with us. We focus on nurturing the personal, professional, and educational qualities of our valuable students, and thus, helping them to shine in their career and grow. Our experts take care to create a delightful atmosphere for the candidates who choose us for growing on a personal as well as professional scale. Besides, our PTE institute strives for excellence and drives maximum efficiency in all our candidates.

</p>
            </div>
        </div>
  
    <section className="iq_best_knowledge_bg">
        <div className="col-md-6 col-sm-offset-6 col-md-offset-6">
            <div className="iq_knowledge_des" style={{padding:"5px"}}>
              
                <div className="iq_heading_1 text-left">
                    <h4>Our  <span>Mission</span></h4>
                </div>
              
                <p>AEC PTE Academy believes in imparting the best PTE education to the students who are looking for excellent and quality culture! Our PTE institute has a clear vision of helping students to meet their career success, therefore, we work for the bright future of our beloved students and thus, help them to understand English language in a better way. We not only incorporate great personality trait in candidates but also teach them how to be successful in their careers. In addition, the organization has a thorough understanding of what student desires and looks for while consulting any PTE institute for enhancing their speaking, listening, reading, and writing skills.  
                <br />At AEC PTE Academy, we are very passionate and committed to our goals of personal and professional development in our students. We’d love to hear from you and work on our clients’ comments for our betterment at every step. Moreover, we have enough resources, knowledge, experience, and a vast network of PTE teachers for assuring you that you will be getting the most suitable tips and tricks to crack PTE exam and score 79+ in your report card.</p>
            </div>
        </div>
    
    </section>
  
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="text progress-caption">
                    
                        <div className="iq_heading_1 text-left">
                            <h4>Why AEC  <span> PTE Academy?</span></h4>
                        </div>
                    
                        <p>Yes, ACE PTE Academy would be helping you succeed not only in scoring a 79+ but also in identifying the major issues that you face while speaking or writing English. The institute provides one-to-one online sessions to students for effective and highly focused learning. Adopting new learning methods, we give exposure to modern techniques and tactics for cracking typical PTE exam. We offer affordable personalized PTE training and also, give you unlimited experts’ feedback to improve your communicative skills.</p>

                        <p><strong>                               At AEC PTE Academy, we give you the following benefits-</strong></p>
                        <ul className="categries-list">
                            <li><a>PTE mock test papers and practice sheets for improvement.</a></li>
                            <li><a>Free PTE study material and modules to all our students</a></li>
                            <li><a>Feedback and special pointers for every type of task in class</a></li>
                            <li><a>Expert answers for any questionnaire or doubts.</a></li>
                            <li><a>Experienced faculties for giving personalized PTE training</a></li>
                            <li><a>Personal attention (online 1-on-1 sessions) to students</a></li>


                        </ul>
                     </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  
                    <div className="iq-progress">
                        <h6 className="progressText"> Morning & Evening Batches</h6>
                        <div className="progress" style={{background:"black!important",width:"40%!important"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                   
                    <div className="iq-progress">
                        <h6 className="progressText"> Repeated Material </h6>
                        <div className="progress" style={{background:"black!important",width:"50%!important"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                  
                    <div className="iq-progress">
                        <h6 className="progressText"> Unlimited Feeback </h6>
                        <div className="progress" style={{background:"black!important",width:"75%!important"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                 
                    <div className="iq-progress">
                        <h6 className="progressText"> Guaranteed Score</h6>
                        <div className="progress" style={{background:"black!important"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    <section className="about-us-section">
        <div className="container">
            <div className="iq_heading_1 text-left">

                <h4>Helping You Succeed - <span> Book a Free Demo Class</span></h4>
                </div>
                <div className="col-md-12" style={{padding:"0"}}>
                    <p>
                        If you are planning to attempt PTE exam soon or preparing for PTE test and have any doubts related to PTE classes, mentorship, or related things, please do not hesitate to book a free.
                        <strong>demo class now! </strong>
                        <br /><br />
                        Don’t give much stress to your mind, just take a free demo session of our PTE class and start your PTE-Academic journey today to meet your dream target!
                    </p>
                </div>

            </div>
    </section>
    </section>
</div>


















<Bottom></Bottom>

  </div>
    )
}
export default About;
