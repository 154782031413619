import react from 'react';
import Bottom from './Bottom'
import * as ReactBootstrap from "react-bootstrap";



function Home() {
    return (
        <div>
            <title>Home Page</title>
           <ReactBootstrap.Carousel fade>
  <ReactBootstrap.Carousel.Item>
    <img
      className="d-block w-100"
      src={process.env.PUBLIC_URL+"./images/slider/slider2.jpg"}
      alt="First slide"
    />
 
  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
    <img
      className="d-block w-100"
      src={process.env.PUBLIC_URL+"./images/slider/slider3.jpg"}
      alt="Second slide"
    />

  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
    <img
      className="d-block w-100"
      src={process.env.PUBLIC_URL+"./images/slider/1.jpg"}
      alt="Third slide"
    />

  </ReactBootstrap.Carousel.Item>
</ReactBootstrap.Carousel>
<div className="iq_heading_1 text-center" style={{margin:"0",paddingTop: "10px"}}>
    <h4 style={{paddingTop:"40px"}}>We Are <span style={{display:"initial"}}>Professional</span></h4>
    <p>
        We, at AEC PTE Academy, aim at supporting our international students to succeed at their English competency test and in life.
      <br></br> We use digital technology to give personalized training to all our candidates seeking in the program.
      <br></br> We are well-known as leading PTE institute for 79+ achievers and render online/offline batches - we are making a difference.
    </p>
</div>
<div className="iq_content_wrap">
    <div className="we_professional_bg" style={{paddingBottom:"53px"}}>
        
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                               
                                <img src={process.env.PUBLIC_URL+"./images/icons/class.png"} style={{width:"35px"}} ></img>
                            </div>
                            <h4 className="title">Personalized Training</h4>
                            <p >
                                In our personalized PTE training, the teaching patterns and exam preparation techniques are customized as per the learning ability of the students.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/book.png"} style={{width:"35px"}} ></img>
                            </div>
                            <h4 className="title">Intensive Study Plan</h4>
                            <p >
                                Our mentors make intensive study plan for PTE exam preparation that helps you in setting the goals in the right direction and work harder to achieve it within few weeks.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/coaching.png"} style={{width:"35px"}}></img>

                            </div>
                            <h4 className="title">Special Weekend Batches</h4>
                            <p >
                                We offer special weekend batches to solve the issues of students and ensure delivery of great results to them anytime.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                              
                                <img src={process.env.PUBLIC_URL+"./images/icons/learning.png"} style={{width:"35px"}} ></img>

                            </div>
                            <h4 className="title">Unlimited Experts Feedback</h4>
                            <p >
                                With our unlimited experts’ feedback, you can improve your PTE score depending on your performance.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                               
                                <img src={process.env.PUBLIC_URL+"./images/icons/mentor.png"} style={{width:"35px"}}></img>
                            </div>
                            <h4 className="title">Online 1-on-1 Sessions</h4>
                            <p >
                                Through our 1-on-1 online learning sessions, you can enjoy personalized PTE classes.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/free.png"} style={{width:"35px"}}></img>

                            </div>
                            <h4 className="title">Free PTE Practical Material</h4>
                            <p >
                                You do not need to pay extra amount to collect the material of the exam as we give free PTE practice sheets to all our dear students.
                            </p>
                        </div>
                    </div>
                    </div>
                <div className="col-md-12">
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/time.png"} style={{width:"35px"}}></img>

                            </div>
                            <h4 className="title">Flexible Schedule</h4>
                            <p >
                                We offer flexible schedule (Monday-Friday) of coaching classes with brain-storming sessions.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/test-results.png"} style={{width:"35px"}}></img>

                            </div>
                            <h4 className="title">Exam Booking Help</h4>
                            <p>
                                We render exam booking assistance to all the students who trust us for dedicated PTE exam preparation. 
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="serviceBox">
                            <div className="service-icon">
                                <img src={process.env.PUBLIC_URL+"./images/icons/improve.png"} style={{width:"35px"}}></img>

                            </div>
                           
                            <h4 className="title"> Improve Quickly</h4>
                            <p>

                                Instantly know which area you are lacking - when you miss a challenge, we'll quickly show you how to improve.
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
    </div>
</div>

<section className="iq_upcomming_bg">
<div className="container">
    <div className="col-md-12">
        <div className="row">
            <div className="col-md-6">
                <img src={process.env.PUBLIC_URL+"./images/home1.jpg"} ></img>
            </div>
            <div className="col-md-6 ">
                <div className="iq_knowledge_des" style={{padding: "0px 18px 0px 40px"}}>
                    <div className="iq_heading_1 text-left">
                        <h4>Get the Best   <span>PTE knowledge</span><br></br> from us!</h4>
                    </div>
                    <h5> HAVE THE COURAGE TO HAVE CONVICTIONS</h5>

                    <p>
                        Are you struggling to get your dream score in Pearson Test of English Academic? We are true mentors offering personalized training sessions with flexible schedule and intensive study plans. We conduct regular classes (Monday-Friday) and also offer special weekend batches for those international students who are busy with their practical lives or have other academic responsibilities. Our PTE Academic course is designed to improve your core communicative skills such as Speaking, Reading, Listening and Writing. We evaluate the candidates’ level of English and guide them accordingly.
                     <br></br>
                        So, do not delay, and get your target score in Pearson Test of English Academic
                    </p>
                        <a className="iq_link_1" href="#/Contact-us">Enroll Now  </a>
                    
                </div>
              
            </div>
            

        </div>
    </div>
    </div>
    </section>


<section className="iq_upcomming_bg">
    <div className="container">
        <div className="iq_heading_1 text-center">
            <h4>Our Success <span> Stories</span></h4>
            <p>
                We love what we do and what we give to our candidates. This is our success mantra, and this is how we are achieving the milestones of success.
            </p>
        </div>
        <div className="row">
            <div className="col-md-12">
            <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"./images/gallery/19.jpg"}>
                
                <div className="col-md-3 col-sm-6">
                    <div className="iq_upcomming_event">
                        <figure style={{borderRadius:"20px"}}>
                            <div className="gallery">
                            
                                <img src={process.env.PUBLIC_URL+"./images/gallery/19.jpg"} alt="Image Here"></img>
                            </div>
                        </figure>
                        <div className="iq_upcomming_des">
                            <span><i className="fa fa-plus"></i></span>
                            <p><strong>Overall Score : 84</strong></p>

                        </div>
                    </div>
                </div>
            </a>
            <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"./images/gallery/12.jpg"}>
                <div className="col-md-3 col-sm-6">
                    <div className="iq_upcomming_event">
                        <figure style={{borderRadius:"20px"}}>
                            <img src={process.env.PUBLIC_URL+"./images/gallery/12.jpg"} alt="Image Here"></img>
                        </figure>
                        <div className="iq_upcomming_des">
                            <span><i className="fa fa-plus"></i></span>
                            <p><strong>Overall Score : 83</strong></p>

                        </div>
                    </div>
                </div>
            </a>
            <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"./images/gallery/13.jpg"}>
                <div className="col-md-3 col-sm-6">
                    <div className="iq_upcomming_event">
                        <figure style={{borderRadius:"20px"}}>
                            <img src={process.env.PUBLIC_URL+"./images/gallery/13.jpg"} alt="Image Here"></img>
                        </figure>
                        <div className="iq_upcomming_des">
                            <span><i className="fa fa-plus"></i></span>
                            <p><strong>Overall Score : 82</strong></p>

                        </div>
                    </div>
                </div>
            </a>
            <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"./images/gallery/18.jpg"}>
                <div className="col-md-3 col-sm-6">
                    <div className="iq_upcomming_event">
                        <figure style={{borderRadius:"20px"}}>
                            <img src={process.env.PUBLIC_URL+"./images/gallery/18.jpg"} alt="Image Here"></img>
                        </figure>
                        <div className="iq_upcomming_des">
                            <span><i className="fa fa-plus"></i></span>
                            <p><strong>Overall Score : 80</strong></p>

                        </div>
                    </div> 
                </div>
            </a>
             </div>
           
         
           
            </div>
            <div className="text-center" style={{marginTop:"10px"}}>
            <a href="#/Gallery" className="iq_link_1" >More Stories</a>
        </div>
       
    </div>
</section>



<section>
    <div className="pricing-table">
        <div className="iq_heading_1 text-center">
            <h4>WELCOME TO <span> OUR CLASSROOM!</span></h4>
            <p>
                Get access to our specially designed classes and embark on your PTE Success journey with us.  
            </p>
        </div>

        <div className="pricing-item">

            <div className="pricing-value" style={{fontSize:"28px"}}>
                Basic
                <span className="undertext">$160</span>
            </div>
            <ul className="pricing-features">
                <li><span className="keywords">Limited </span> Access</li>

                <li><span className="keywords">1-Week </span> Duration</li>
            </ul>
            <a className="iq_link_1" style={{color:"white!important",fontWeight:"100",marginBottom:"20px",padding:"5px",fontSize:"15px"}} href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">
                Enroll Now 
            </a>
        </div>
        <div className="pricing-item">

            <div className="pricing-value"style={{fontSize:"28px"}}>
                Bronze
                <span className="undertext">$300</span>
            </div>
            <ul className="pricing-features">
                <li><span className="keywords"> Conditioned</span>  Access</li>
                <li>4-Weeks  <span className="keywords">Duration</span></li>
               

            </ul>
            <a className="iq_link_1" style={{color:"white!important",fontWeight: "100",marginBottom: "20px",padding:"5px",fontSize:"15px"}} href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">
                Enroll Now
            </a>        </div>

        <div className="pricing-item pricing-featured">
            <div class='selected'>Recommended</div>

            <div className="pricing-value"style={{fontSize:"28px"}}>
                Gold
                <span className="undertext">$650</span>
            </div>
            <ul className="pricing-features">
                <li><span className="keywords">Unlimited </span> Access</li>
                <li> 12-Weeks <span className="keywords">     Duration</span></li>
             

            </ul>
            <a className="iq_link_1" style={{color:"white!important",fontWeight: "100",marginBottom: "20px",padding:"5px",fontSize:"15px"}} href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">
                Enroll Now
            </a>        </div>
        <div className="text-center" style={{marginTop:"50px"}}>
            <a href="#/Packages" className="iq_link_1" >View More</a>
        </div>
    </div>
</section>

<section className="iq_number_count_bg">
    <div className="container">
        <div className="row">
   
            <div className="col-md-6" style={{padding:"0px"}}>
                <div className="iq_heading_1 text-left">
                    <h4 className="White">ATTEND PTE CLASSES ANYTIME, ANYWHERE!</h4>
                    <p className="White">
                        Pearson Test of English Academic commonly known as PTE test is a mandatory language proficiency test for all the students who want to migrate or study abroad. The PTE score is accepted by more than 90% foreign universities and assists in easing the process of getting a visa and supporting immigration applications.
                        <br></br><br></br>
                        AEC PTE Academy is the best PTE institute in Melbourne that provides personalized PTE training to learn and perk up your chances of getting success. We are a leading PTE Institute for 79+ that love to work on your weak areas and design intensive study plan covering all the basic four modules of the PTE course. Being the best institute for online classes, AEC PTE Academy keeps you on the right track and renders group, as well as 1-on-1 sessions to make the change happen in students’ score. We offer special weekend batches following a flexible schedule of coaching classes with strong strategic tips and tricks so that you can pass the exam with a highest band score. The best thing is that you don’t need to pay extra amount to collect the material of the exam, as we provide free PTE practice material to all our dear students. So, what you are waiting for? Join PTE classes at AEC PTE Academy in Melbourne and achieve your dream score.
                        </p>
                </div>
            </div>

            <div className="col-md-6" style={{marginTop:"50px"}}>
                <div className="iq_search_courses">
                    <h4>Book a FREE Demo Class  </h4>
                    <form>
                   
                        <div className="iq-input">
                           
                                <input type="text" placeholder="Name"></input>
                        </div>
                 
                        <div className="iq-input">
                            <input type="email" placeholder="E-mail"></input>
                        </div>
                     
                        <div className="iq-input">
                            <input type="text" placeholder="Phone Number"></input>
                        </div>
                    
                        <div className="iq-input">
                            <select id="form_option" className="form-control form_control required" name="Intrestedin" required="required" data-error="Option is required." placeholder="Target Score">
                                <option value="">Target Score</option>
                                <option value="50+ band"> 50+ band</option>

                                <option value="65+ band">65+ band</option>
                                <option value="79+ band">79+ band</option>

                            </select>


                        </div>
               
                        <div className="iq-input">

                            <textarea placeholder="Message"></textarea>
                        </div>

                        <div className="col-md-6" style={{padding: "0"}}>
                            <button className="iq_link_1" type="submit"> Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


 
<Bottom></Bottom>
 </div>
    )
}
export default Home;
