import react from 'react';
import Bottom from './Bottom'


function Listening() {
    return (
        <div>
<title>Listening</title>
<div className="iner_banner course-single">
    <div className="container">
        <h5>Listening</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/home">Home</a></li>
                <li><a href="#">Listening</a></li>
            </ul>
        </div>
    </div>
</div>

<div className="iq_content_wrap">
    <section className="coures_single_page">
        <div className="container">
            <div className="row">
                <div className="col-md-12" style={{background: "#f6f6f6!important"}}>
                    <div className="course_single_heading">
     
                    </div>
                 
                    <div className="about_courses">
                        <div className="about_courses_thumb">
                            <section className="listening">
                                <div className="col-md-6 col-sm-offset-6 col-md-offset-6">
                                    <div className="iq_knowledge_des" style={{padding:"0"}}>
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE Listening  <span>  MODULE</span> </h4>
                                        </div>
                                        <p>
                                          
                                            The listening module is the final section of the examination and takes approximately 45-60 minutes to complete. This section assesses your listening skills through audio and video clips that are played automatically. You can adjust the volume to a comfortable level but the audio clips will only be played ONCE. You cannot choose to listen to the passage again. Therefore, it is most important that you remain alert and careful for the duration of this section. You are also allowed to take notes, so make sure you practice to extract keywords from the spoken text. The content of most audio clips will be related to an academic topic from the natural, social, or earth sciences. You do not need any prior knowledge of these subjects to answer questions correctly as all the required information will be provided in the audio itself.

                                        </p>
                                    </div>
                                </div>
                            </section>


                            <div id="my-tab-content" className="tab-content">
                                <div className="tab-pane active" id="description">
                                    <div className="about_courses_thumb_capstion">
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE Listening  <span> OVERVIEW</span></h4>
                                        </div>
                                        <br />
                                        <p>
                                            The Listening module consists of 8 separate sections. You can expect 2-3 questions from each task, with the exception of the final ‘Write from Dictation’ task which usually has 3-4 questions.
                                            Many of these tasks also combine reading and writing skills to ensure that you have a well-rounded grasp of the English language.

                                        </p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>1.</span>Summarize Spoken Text</span><br /><br />In this task, you will see an audio clip box and a text box on your screen. After 12 seconds, the recording prompt will start to play automatically. The length of the audio clip will be between 60-90 seconds and you have to write a short summary of the key ideas presented. You must write between 50-70 words clearly stating the most important points made by the speaker in the audio snippet. You will have 10 minutes to finish this task, timed individually.
</p>                                     
                                                                       
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>2.</span>Multiple Choice, Choose Multiple Answers</span><br /><br />Here, you will see an audio recording box followed by a multiple-choice question underneath. After 7 seconds, the audio recording will start playing automatically and based on its contents, you must answer the given question by choosing all the correct options. The prompt length will be between 40-90 seconds. You will need to select more than one answers from the given list of options. If one or more of your selected answers is incorrect or a correct response has not been selected, you will only receive partial credit.
                                        </p>      
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>3.</span>Fill in the Blanks</span><br /><br />Under the audio box on your screen, you will find a short paragraph of text with certain words/phrases missing. Based on the audio recording, you must type in the appropriate words in the blank spaces. The transcript paragraph you will be completing must match the audio recording exactly. The audio will start playing automatically after a delay of 7 seconds and usually lasts for 30-60 seconds. Since the audio is played only once, it is advised that you keep taking quick notes for later reference.
                                        </p>                                   
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>4.</span>Highlight Correct Summary</span><br /><br />An audio recording of 30-90 seconds will start playing automatically after a 10-second delay. For this item type, you need to select the best summary of the audio from the given options. The options tend to be quite similar and you’ll need to listen carefully to evaluate which is the best possible description. You’ll only be able to listen to the recording once and only one of the answers is correct. This task assesses listening and reading skills simultaneously. It is advised that you do not try to listen and read at the same time.
                                        </p>                                     
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>5.</span>Multiple Choice, Choose Single Answer</span><br /><br />5 seconds after you arrive at the question, an audio clip of 30-60 seconds will start to play. The audio box is followed by a multiple-choice type question. You need to select a single answer from the given options based on the contents of the recording. Since this task has only one correct response, you will receive no credit for an incorrect or missing response.
                                        </p>                                     
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>6.</span>Select Missing Word</span><br /><br />In this task, you’ll see an audio box and a multiple choice type question on your screen. After 7 seconds, a recording of 20-70 seconds will start to play automatically. Here, the last word (or group of words) will be missing from the spoken passage. You will instead hear a beep sound. You must choose the answer that best fits the missing phrase in the audio clip. There is only a single correct response and you must select the most appropriate option.
                                        </p>                                     
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>7.</span>Highlight Incorrect Words</span><br /><br />This task will provide you with an audio recording as well as a nearly exact transcript of the clip. The transcript is complete, but some of the words differ from that of the speaker’s in the audio. The prompt length is likely to be 15-50 seconds and starts playing automatically after 10 seconds. You must highlight the mismatched words in the transcript with your mouse cursor. If you miss one or more correct responses or select words that match the recording, partial credit scoring will apply.
                                        </p>                                     
                                        <p style={{paddingTop:"24px"}}>
                                            <span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>8.</span>Write from Dictation</span><br /><br />You will see an audio box followed by a blank text box on your screen. After 7 seconds, you will hear a single short sentence that lasts no more than 5 seconds. After listening, you must type in the sentence spoken as accurately as possible. The goal is to type out the audio verbatim and you receive a point for every correct word in your response. This task assesses both listening and writing skills, so make sure to check word spellings thoroughly.
                                        </p>                                     

                                        <div className="border-div"></div>
                                      
                                    </div>
                                
                                    <div className="iq_heading_1 text-left">
                                        <h4>REQUIRED  <span> SKILLS</span> </h4>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" >
                                                    <div className="text">
                                                        <img src={process.env.PUBLIC_URL+"images/icons/check.png"} style={{paddingBottom:"25px",width:"70px"}}></img>
                                                      <br></br>
                                                        <h4><a href="#">Active listening</a></h4>
                                                        <p>The candidate must be able to capture essential supporting points from audio/videos.</p>
                                                    </div>

                                                </div>
                                            </div>
                                        
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" >
                                                    <div className="text">
                                                        <img src={process.env.PUBLIC_URL+"images/icons/check1.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                        <h4><a href="#">Academic vocabulary</a></h4>
                                                        <p>The test taker must have a strong knowledge of academic vocabulary. </p>
                                                    </div>

                                                </div>
                                            </div>
                                    
                                            <div className="col-md-4 hidden-sm">
                                                <div className="iq_teacher_style_1" >

                                                    <div className="text">
                                                        <img  src={process.env.PUBLIC_URL+"images/icons/check2.png"}  style={{paddingBottom:"25px",width:"70px"}}></img>
                                                        <br></br>

                                                        <h4><a href="#">Understanding of spoken English</a></h4>
                                                        <p>Develop some strategies to write down excellent notes for your answers.</p>
                                                        <br /><br />


                                                    </div>
                                                </div>
                                            </div>
                                     
                                        </div>
                                        <div className="border-div"></div>
                                        <div className="iq_heading_1 text-left">
                                            <h4>SCORING <span>  OVERVIEW </span></h4>
                                            <p>Every task in PTE Reading test has defined score points, so if you want to score 79+ in PTE Reading module, you must understand the scoring criteria.  </p>
                                        </div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th > Item Type</th>
                                                    <th > Time allowed </th>
                                                    <th >Number of Items</th>
                                                    <th > Scoring  </th>
                                                    <th > Communicative Skills, enabling skills and other triats scored </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                   
                                                    <td>Summarize spoken text</td>
                                                    <td>20-30 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Partial Credit</td>
                                                    <td>Listening and writing grammer, vocabulary, spelling Content</td>
                                                   
                                                   
                                                </tr>


                                                <tr>

                                                    <td>Multiple choice, choose multiple answers</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Partial Credit ( For Each Correct Response. Point Deducted For Incorrcet Option Chosen )</td>
                                                    <td>Listening</td>
                                              

                                                </tr>
                                                <tr>

                                                    <td>Fill in the blanks</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Partial Credit (Each Correct Word Spelled Correctly)</td>
                                                    <td>Listening and writing</td>
                                              

                                                </tr>
                                                <tr>

                                                    <td>Highlight correct summary</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Correct / Incorrect</td>
                                                    <td>Listening and reading</td>
                                                  

                                                </tr>
                                                <tr>

                                                    <td>Multiple-choice,choose single answer</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Correct / Incorrect</td>
                                                    <td>Listening</td>
                                                 

                                                </tr>
                                                <tr>

                                                    <td>Selected missing word</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Correct / Incorrect</td>
                                                    <td>Listening</td>
                                                

                                                </tr>
                                                <tr>

                                                    <td>Highlight correct summary</td>
                                                    <td>23-28 minutes</td>
                                                    <td>2-3</td>
                                                    <td>Partial Credit (For Each Word. Points Deducted For Incorrect Options Chosen )</td>
                                                    <td>Listening and reading</td>
                                              

                                                </tr>
                                                <tr>

                                                    <td>Write from dictation</td>
                                                    <td>23-28 minutes</td>
                                                    <td>3-4</td>
                                                    <td>Partial Credit (For Each Word Spelled Correctly)</td>
                                                    <td>Listening and writing</td>
                                               

                                                </tr>
          
                                            

                                            </tbody>

                                        </table>

                                    </div>
                                 
                                    <div className="border-div" style={{paddingTop:"40px",marginBottom: "-9px"}}></div>

                                    <div className="iq_heading_1 text-left" style={{paddingTop: "55px"}}>
                                        <h4>EXPERT TIPS FOR  <span>79+ IN PTE  LISTENING  </span></h4>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6">
                                            <ul className="categries-list">
                                                <li><a href="#">Listen to a wide range of speakers from different backgrounds during preparation.</a></li>
                                                <li><a href="#">Pay close attention to stressed words as these are usually the most important.</a></li>
                                                <li><a href="#">Be aware of tasks having negative marking.</a></li>
                                                <li><a href="#">Try to understand the meaning of the academic text being spoken. If you can anticipate the flow of the passage, answering questions will be much easier.</a></li>
                                                <li><a href="#">Manage your time carefully and make sure you’re not exhausted by the previous modules.</a></li>
                                          
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="border-div"></div>


                            </div>
                        </div>
                    </div>
               
                </div>

            </div>
        </div>
    </section>
</div><Bottom></Bottom>

        </div>
    )
};

export default Listening;
