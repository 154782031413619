import React from 'react'
import Bottom from './Bottom'

function Writing(){
    return(
        <div>
            <title>Writing</title>
<div className="iner_banner course-single">
    <div className="container">
        <h5>Writing</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Writing</a></li>
            </ul>
        </div>
    </div>
</div>
<div className="iq_content_wrap">
    <section className="coures_single_page">
        <div className="container">
            <div className="row">
                <div className="col-md-12" style={{background: "#f6f6f6!important"}}>
                    <div className="course_single_heading">
                 
                    </div>
               
                    <div className="about_courses">
                        <div className="about_courses_thumb">
                            <section className="writing">
                                <div className="col-md-6 col-sm-offset-6 col-md-offset-6">
                                    <div className="iq_knowledge_des" style={{padding:"0"}}>
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE WRITING   <span>  MODULE</span> </h4>
                                        </div>
                                        <p>

                                            Writing module is the second section of Part 1 of PTE-A test which means that a sperate part is not allocated to this module, but it continues directly after submitting responses for the Speaking module. This module is designed to assess the quality of your writing, along with grammatical accuracy. You will be graded based on the content, grammar, written discourse, spellings, vocabulary, and form of your responses. In other words, credit is awarded based on how well you form sentences, how coherent your viewpoint is, and your general linguistic range. This section comprises of two types of items - summary and essay. The total length of time for this module depends on the combination of the above tasks you receive. Generally, you will be asked to write two summaries and one essay, or three summaries and one essay, or two summaries and two essays.  This section requires you to respond to the prompt with a paragraph in standard academic English. You are free to use spelling conventions of the USA, United Kingdom, Australia, or Canada however, consistency must be maintained within the convention of choice.
                                        </p>
                                       
                                    </div>
                                </div>
                             
                            </section>


                         
                            <div id="my-tab-content" className="tab-content">
                                <div className="tab-pane active" id="description">
                                    <div className="about_courses_thumb_capstion">
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE Writing  <span> OVERVIEW</span></h4>
                                        </div>
                                        <br />
                                       
                                        <p style={{paddingTop: "24px"}}>
                                            <span style={{fontSize: "28px"}}><span style={{marginRight: "5px"}}>1.</span>Summarize Written Text</span><br /><br />This is a short-answer type question where you need to summarize the given text in one sentence. This task assesses you for Writing as well as Reading modules. The passage length could be up to 300 words and the time limit for answering each prompt is 10 minutes. You must include all the main points of the reading passage in a complete complex compound single sentence of no more than 75 words. You can use ‘Total Word Count’ counter below the response box to manage your word count for the task. If you write fewer than five words or more than 75 words, your response will not be scored. The most important aspect of this item type is limiting your response to one sentence ONLY. This means that you can use a full stop only once in your answer, however, you can use commas and semicolons more than once to structure your sentence. You should include all major ideas and themes within the given word limit from the passage in your summary to receive full credit for your answer. You also have the option to cut/copy/paste the given text to make sure your summary is accurate and thoughtful. 
                                        </p>

                                        <p style={{paddingTop: "24px"}}>
                                            <span style={{fontSize: "28px"}}><span style={{marginRight: "5px"}}>2.</span>Write Essay</span><br /><br />
                                            This is a long-answer type question where you need to write a short essay on a given prompt. The essay length must be between 200-300 words, and the prompt length is usually limited to one or two sentences. You will get 20 minutes to finish each task of this type. Writing under 200 words or over 300 words will result in a penalty and loss in credit, however, no credit will be given for any irrelevant or no response. Your response on this task is generally judged on your ability to write a persuasive or argumentative essay on the given topic.  The essay is used to assess your academic writing skills and you will be graded on factors such as structure, coherence, vocabulary range, grammar usage and mechanics. Like other writing tasks, you can use ‘Total Word Count’ to count the number of words you write. You will also see the cut/copy/paste buttons on the screen which you can use to write a concise, coherent, and logical response.                                         </p>
                                      
                                        <div className="border-div"></div>
                                    
                                    </div>
                            
                                    <div className="iq_heading_1 text-left">
                                        <h4>REQUIRED  <span> SKILLS</span> </h4>
                                        <br /><p>
                                                  To achieve higher scores in PTE Writing module, you must demonstrate correct grammatical usage and consistent control of academic written English. </p>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" style={{minHeight:"378px"}}>
                                                    <div className="text">
                                                    <img src={process.env.PUBLIC_URL+"images/icons/check.png"} style={{paddingBottom:"25px",width:"70px"}}></img>
                                                        <br />
                                                        <h4><a href="#">	Grammar and Spellings</a></h4>
                                                        <p>The test taker must present high proportions of grammatically correct written compound sentences that clearly depict the context of the prompt.  </p>

                                                    </div>

                                                </div>
                                            </div>
                                      
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" style={{minHeight:"378px"}}>
                                                    <div className="text">
                                                    <img src={process.env.PUBLIC_URL+"images/icons/check1.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                        <h4><a href="#">Written Discourse </a></h4>
                                                        <p>The response should present 3Cs of good academic writing – Clarity, Coherence, Conciseness.</p>

                                                    </div>

                                                </div>
                                            </div>
                                          
                                            <div className="col-md-4 hidden-sm">
                                                <div className="iq_teacher_style_1" style={{minHeight:"378px"}}>

                                                    <div className="text">
                                                    <img src={process.env.PUBLIC_URL+"images/icons/check2.png"} style={{paddingBottom:"25px",width:"70px"}}></img>
                                                        <br />

                                                        <h4><a href="#">Content and Linguistic Range</a></h4>
                                                        <p>You must present variety of academic vocabulary with a consistent use of a single spelling convention. </p>
                                                        <br /><br />


                                                    </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                        <div className="border-div"></div>
                                        <div className="iq_heading_1 text-left">
                                            <h4>SCORING <span>  OVERVIEW </span></h4>
                                            <p>Every task in PTE Reading test has defined score points, so if you want to score 79+ in PTE Reading module, you must understand the scoring criteria.  </p>
                                        </div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th > Task Type</th>
                                                    <th > Scoring  </th>
                                                    <th >Communicative Skills Assessed</th>
                                                    <th > Enabling Skills  </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>Summaries Written Text</td>
                                                    <td>Partial Credit</td>
                                                    <td>Reading<br />
Writing
</td>
                                                    <td>Grammar<br />
Vocabulary<br />
Content<br />
 Form
</td>


                                                </tr>


                                                <tr>

                                                    <td>Write Essay</td>
                                                    <td>Partial Credit </td>
                                                    <td>Writing </td>
                                                    <td>Grammar
Vocabulary<br />
Spelling<br />
Written Discourse<br />
Content<br />
Form
</td>


                                                </tr>
                                             


                                            </tbody>

                                        </table>

                                    </div>
                                
                                    <div className="border-div" style={{paddingTop:"40px",marginBottom: "-9px"}}></div>


<div className="iq_heading_1 text-left" style={{paddingTop: "55px"}} >
                                        <h4>EXPERT TIPS FOR  <span>79+ IN PTE  SPEAKING  </span></h4>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6">
                                            <ul className="categries-list">
                                                <li><a href="#">Organize your sentences and paragraphs in logically.</a></li>
                                                <li><a href="#">Use words and phrases appropriate to the context of the given question.</a></li>
                                                <li><a href="#">When writing about your opinion, support it with details, examples, and personal experiences.</a></li>
                                                <li><a href="#">Always keep an eye on the word count at the bottom of the typing box.</a></li>
                                                <li><a href="#">Avoid repeating words and sentences.</a></li>
                                                <li><a href="#">Always demonstrate a broad academic vocabulary range.</a></li>
                                                <li><a href="#">Always meet the length requirement of each question.</a></li>
                                        


                                            </ul>
                                        </div>

                                    </div>
                                 
                                </div>
                                <div className="border-div"></div>


                            </div>
                        </div>
                    </div>
                   
                </div>

            </div>
        </div>
    </section>
</div>



<Bottom></Bottom>

        </div>
    )
}
export default Writing;