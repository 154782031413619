import React from 'react';
import Bottom from './Bottom'

function Gallery(){
    return(
        <div>
            <title>Gallery</title>
<div className="iner_banner">
    <div className="container">
        <h5>Gallery</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Gallery</a></li>
            </ul>
        </div>
    </div>
</div>

<section className="iq_upcomming_bg">
    <div className="container">
        <div className="iq_heading_1 text-center">
            <h4>Our Success <span> Stories</span></h4>
            <p>
                We love what we do and what we give to our candidates. This is our success mantra, and this is how we are achieving the milestones of success
            </p>
        </div>
     
        <div className="row">
            <div className="col-md-12">
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/22.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                               
                                    <img src={process.env.PUBLIC_URL+"images/gallery/22.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 57</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/20.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                             
                                <img src={process.env.PUBLIC_URL+"images/gallery/20.jpg"} alt="Image Here"></img>

                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 79</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/21.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/21.jpg"} alt="Image Here"></img>

                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 58</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/11.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/11.jpg"} alt="Image Here"></img>  
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 71</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
             
            </div>
            <div className="col-md-12">
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/12.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/12.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 83</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/13.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/13.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 82</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/14.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/14.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 58</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/15.png"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/15.png"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 71</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
              
            </div>
            <div className="col-md-12">
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/16.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/16.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 71</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/17.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/17.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 68</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/1.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/1.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 67</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/2.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/2.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 59</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
               
            </div>
            <div className="col-md-12">
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/3.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/3.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 68</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/4.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/4.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 74</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/5.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/5.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 66</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/6.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/6.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 75</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
             
                
            </div>
            <div className="col-md-12">
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/7.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/7.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 89</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/8.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/8.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 63</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
                <a data-fancybox="gallery" href={process.env.PUBLIC_URL+"images/gallery/9.jpg"}>
                    <div className="col-md-3 col-sm-6">
                        <div className="iq_upcomming_event">
                            <figure>
                                <img src={process.env.PUBLIC_URL+"images/gallery/9.jpg"} alt="Image Here"></img>
                            </figure>
                            <div className="iq_upcomming_des">
                                <span><i className="fa fa-plus"></i></span>
                                <p><strong>Overall Score : 89</strong></p>

                            </div>
                        </div>
                    </div>
                </a>
            </div>
            
        </div>
    </div>
</section>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.css" type="text/css" media="screen" />
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js"></script>
       
<Bottom></Bottom>
 </div>
    )
}
export default Gallery;