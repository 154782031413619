import React from 'react'
import Bottom from './Bottom'

function Reading(){
    return(
        <div>
            <title>Reading</title>
<div className="iner_banner course-single">
    <div className="container">
        <h5>Reading</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Reading</a></li>
            </ul>
        </div>
    </div>
</div>

<div className="iq_content_wrap">
    <section className="coures_single_page">
        <div className="container">
            <div className="row">
                <div className="col-md-12" style={{background: "#f6f6f6!important"}}>
                    <div className="course_single_heading">
               
                    </div>
             
                    <div className="about_courses">
                        <div className="about_courses_thumb">
                            <section className="reading">
                                <div className="col-md-6 col-sm-offset-6 col-md-offset-6">
                                    <div className="iq_knowledge_des" style={{padding:"0"}}>
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE READING <span> SECTION</span> </h4>
                                        </div>
                                        <p>Part 2 of PTE-A test consists of Reading module and completes approximately within 32-40 minutes. This single timed part comprises of 5 independent and integrated tasks (15-20 questions in total) which also contribute marks in Writing module. It is important you should skim through the passage as quickly as possible because reading tasks are not timed individually. The candidate must refer to the timer in the upper right corner of the computer screen to manage his/her time effectively. This section tests the candidates in their understanding of Academic English, grammar knowledge, English language rules and so on. Mostly the selected question text covers academic subjects such as social sciences, geography, history, natural science, psychology and humanities. </p>
                                    </div>
                                </div>
                            </section>


                            <div id="my-tab-content" className="tab-content">
                                <div className="tab-pane active" id="description">
                                    <div className="about_courses_thumb_capstion">
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE READING  <span> OVERVIEW</span></h4>
                                        </div>
                                        <br></br>
                                        <p>PTE Reading module comprises of 5 main tasks. Below, we will break down each part of this section to give you a brief idea what does this section look like, what a candidate can expect on the day of test and what is the marking criteria of PTE-A Reading module.  </p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"28px"}}>1.</span>Multiple Choice – Choose Single Answer</span><br></br><br></br>In Multiple Choice – Choose Single Answer, a small passage around 100-150 words will appear on the screen with a question and its possible response options – select only one response based on content, information or tone of the text. This item type has 2-3 questions and mainly tests your reading skills only. You should always read the question, understand the writer’s tone, topic of the passage and overall organisation of ideas before choosing the right option.  </p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"28px"}}>2.</span>Multiple Choice – Choose Multiple Answers</span><br></br><br></br>This task type is quite similar to Multiple Choice – Choose Single Answer but there will be more than one answer to choose from the given list of possible response options. This item type has 2-3 questions which assess your reading skills only. After reading a passage of 200-300 words, you need to select more than one response answers. The challenging part of this task type is the scoring criteria. For each correct answer, you will get 1 point, however, you will lose -1 point if you choose an incorrect answer. Also, avoid ticking all the options because you will lose score points. </p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"28px"}}>3.</span>Reorder Paragraphs </span><br></br><br></br>In this task type, as the name suggest – re-order the paragraphs – so several text boxes with paragraphs appear on screen in random order, simply restore the original order by dragging and dropping the text boxes from left panel (Source) to the right panel (Target). This item type has 2-3 questions which assess your reading skills only. You must organise the sentences into correct order to get your reading score. This task type tests your on your ability to understand the cohesion of an academic text. For every correct pair that you place in correct order, you will be awarded with 1 score point, however, you will receive maximum score points if the whole paragraph is in the correct order.</p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"28px"}}>4.</span>Reading: Fill in the Blanks</span><br></br><br></br>This is one of the most important tasks in PTE Reading module. In this task type, a small passage around 80-90 words long will appear on screen with some gaps. You will also be provide with a list of proposed words in a box below - you can drag and drop the correct word to fill in the blanks. This item type has 4-5 questions which judge you for your Reading skills only based on your knowledge of collocation, grammatical cues, parts of speech, tenses, phrasal verbs and so on. You get maximum score if you fill all the gaps correctly.  </p>
                                        <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"28px"}}>5.</span>Reading & Writing: Fill in the Blanks</span><br></br><br></br>This is another highly important question of PTE Reading section which assess you for two modules – Reading and Writing. In this item task, a text up to 300 words will appear on the screen with multiple gaps, fill those gaps by selecting the most appropriate answers from the given drop-down lists of response options. There will be 5-6 questions for this task and every question will have multiple number of blanks to fill in. This task assesses your ability to use contextual and grammatical cues to identify words that complete a reading text. You will receive a credit point in both Reading and Writing sections for every correct word you insert into the gaps, so choose your answers wisely. </p>
                                       
                                        


                                        <div className="border-div"></div>
                                       
                                    </div>
                              
                             
                                    <div className="iq_heading_1 text-left">
                                        <h4>REQUIRED  <span> SKILLS</span> </h4>
                                    </div>
                                    <p>                                        To score maximum bands in PTE Reading module, you must improve your understanding of academic vocabulary, use of collocation, grammar, and parts of speech. </p>


                                    <div className="col-md-12">
                                        <div className="row">
                                  
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" style={{minHeight:"366px"}}>
                                                    <div className="text">
                                                        <img src={process.env.PUBLIC_URL+"./images/icons/check.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                        <h4><a href="#">Academic Vocabulary </a></h4>
                                                        <p> The test-taker must be able to understand three important aspects of academic vocabulary i.e. general academic vocabulary, discipline-specific words and phrases, and nominalisation. </p>

                                                    </div>

                                                </div>
                                            </div>
                                
                                            <div className="col-md-4 col-sm-6">
                                                <div className="iq_teacher_style_1" style={{minHeight:"366px"}}>
                                                    <div className="text">
                                                        <img src={process.env.PUBLIC_URL+"./images/icons/check1.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                        <h4><a href="#">Knowledge of Collocation</a></h4>
                                                        <p>The candidate must have the knowledge of collocation because it can help him/her to recognize the correct words in fill in the blanks tasks. </p>

                                                    </div>

                                                </div>
                                            </div>
                                   
                                            <div className="col-md-4 hidden-sm">
                                                <div className="iq_teacher_style_1" style={{minHeight:"366px"}}>

                                                    <div className="text">
                                                        <img src={process.env.PUBLIC_URL+"./images/icons/check2.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                        <br></br>

                                                        <h4><a href="#">Grammar</a></h4>
                                                        <br></br><br></br>
                                                        <p> The test taker must have the ability to understand the fundamentals of grammar such as tenses, parts of speech and so on.   </p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-div"></div>
                                        <div className="iq_heading_1 text-left">
                                            <h4>SCORING <span>  OVERVIEW </span></h4>
                                            <p>Every task in PTE Reading test has defined score points, so if you want to score 79+ in PTE Reading module, you must understand the scoring criteria.  </p>
                                        </div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{minWidth: "248px"}}> Task Type </th>
                                                    <th style={{minWidth: "190px"}}> Number of Tasks </th>
                                                    <th style={{minWidth: "275px"}}> Communicative Skills Assessed</th>
                                                    <th style={{minWidth: "275px"}}> Scoring  </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> Multiple Choice <br></br>Choose Single Answer</td>
                                                    <td>2-3</td>
                                                    <td> Reading</td>
                                                    <td>
                                                        Correct/Incorrect<br></br>
                                                        1 Correct response<br></br>
                                                        0 Incorrect response


                                                    </td>
                                                </tr>


                                                <tr>
                                                    <td> Multiple Choice <br></br>Choose Multiple Answers</td>
                                                    <td> 2-3</td>
                                                    <td> Reading</td>

                                                    <td>
                                                        Partial credit, points deducted for incorrect option/s chosen:<br></br>
                                                        1 Each Correct response<br></br>
                                                        -1 Each Incorrect response<br></br>
                                                        0 Minimum score

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td> Re-order Paragraphs	</td>
                                                    <td> 2-3</td>
                                                    <td> Reading</td>

                                                    <td>
                                                        Partial Credit:<br></br>
                                                        1 Each pair of correct adjacent pair<br></br>
                                                        0 Minimum score

                                                    </td>


                                                </tr>
                                                <tr>
                                                    <td>Reading: <br></br>Fill in the Blanks  </td>
                                                    <td>4-5</td>
                                                    <td> Reading</td>

                                                    <td>
                                                        Partial Credit:<br></br>
                                                        1 Each correctly completed blank<br></br>
                                                        0 Minimum score

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td> Reading & Writing:<br></br> Fill in the blanks</td>
                                                    <td>5-6</td>
                                                    <td> Reading<br></br>
Writing
</td>

                                                    <td> Partial Credit:<br></br>
1 Each correctly completed blank<br></br>
0 Minimum score
   	</td>

                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                  
                                    <div className="border-div" style={{paddingTop:"40px",marginBottom: "-9px"}}></div>


<div className="iq_heading_1 text-left" style={{paddingTop: "55px"}} >
                                        <h4>EXPERT TIPS FOR  <span>79+ IN PTE READING  </span></h4>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6">
                                            <ul className="categries-list">
                                                <li><a href="#"> Always read the question before skimming through the passages in Multiple Choice Questions.</a></li>
                                                <li><a href="#"> Skimming and skipping technique will help you to manage your time efficiently.</a></li>
                                                <li><a href="#"> Develop a habit of reading newspaper, academic articles and fictional books.</a></li>
                                                <li><a href="#"> Improve your vocabulary, learn words regularly.</a></li>
                                                <li><a href="#"> Comprehending explicit and implicit information in the passage will help you to score 79+ in PTE Reading.</a></li>
                                                <li><a href="#"> Following a logical or chronological sequence of events in Re-order paragraphs will improve your reading score.</a></li> 
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="border-div"></div>


                            </div>
                        </div>
                    </div>
                
                </div>

            </div>
        </div>
    </section>
</div>
<Bottom></Bottom>

        </div>
    )
}
export default Reading;