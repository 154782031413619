import React from 'react';
import * as ReactBootstrap from "react-bootstrap";


function Bottom(){
    return(
      <div>
            {/* <ReactBootstrap.Carousel>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial"style={{minHeight:"322px"}}>
                            <p>“ AEC PTE Academy gives an excellent coaching, including not only excellent techniques and strategies but also an excellent dedication and motivation to improve the results. Tutor always keen on making time for one to one practice when you require to improve any weak areas of English.
I highly recommend AEC PTE Academy if you want to nail your English exam.”</p>
                            <div class="coures_instractor_thumb">
                              
                                <div class="thumb_capstion">
                                    <h5><a href="#">Ranjith Palaniappan</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>
 
  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial" style={{minHeight:"322px"}}>
                            <p>“ I have achieved more than my desired my PTE score ( 9 bands in all). I give my credit to AEC Global, it is them ( Sonia and Pankaj ) who guided me in the right path and gave me tips and trained me in improving my grammer which made a lot of difference in getting higher marks in PTE! I highly recommend AEC global as they are very familiar with the PTE software and guide you with extra concern and care towards students individually! ”</p>
                            <div class="coures_instractor_thumb">
                               
                                <div class="thumb_capstion">
                                    <h5><a href="#">Nawaz Mohammed</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>

  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial" style={{minHeight:"322px"}}>
                            <p>“ PTE Academic is all about mastering the tricks and algorithms which can guarantee you good score.

I am deeply satisfied from the quality of teaching that was provided by the institute that enabled me to achieve my desired score. ”</p>
                            <div class="coures_instractor_thumb">
                             
                                <div class="thumb_capstion">
                                    <h5><a href="#">Faizan Jamil</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>
  </ReactBootstrap.Carousel.Item>
</ReactBootstrap.Carousel> */}
          <section style={{background:"#f6f6f6"}}>
            <div class="container">
                <div class="iq_heading_1 text-center">
                    <h4>student <span> testimonial</span></h4>
                    <p>
                      
                        Client's satisfaction is our success! This is something that our previous students think of us.

                    </p>
                </div>
            
                <div class="testimonial-slider">
                    
                  
                    
                <ReactBootstrap.Carousel>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial"style={{minHeight:"322px"}}>
                            <p>“ AEC PTE Academy gives an excellent coaching, including not only excellent techniques and strategies but also an excellent dedication and motivation to improve the results. Tutor always keen on making time for one to one practice when you require to improve any weak areas of English.
I highly recommend AEC PTE Academy if you want to nail your English exam.”</p>
                            <div class="coures_instractor_thumb">
                              
                                <div class="thumb_capstion">
                                    <h5><a href="#">Ranjith Palaniappan</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>
 
  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial" style={{minHeight:"322px"}}>
                            <p>“ I have achieved more than my desired my PTE score ( 9 bands in all). I give my credit to AEC Global, it is them ( Sonia and Pankaj ) who guided me in the right path and gave me tips and trained me in improving my grammer which made a lot of difference in getting higher marks in PTE! I highly recommend AEC global as they are very familiar with the PTE software and guide you with extra concern and care towards students individually! ”</p>
                            <div class="coures_instractor_thumb">
                               
                                <div class="thumb_capstion">
                                    <h5><a href="#">Nawaz Mohammed</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>

  </ReactBootstrap.Carousel.Item>
  <ReactBootstrap.Carousel.Item>
  <div class="testimonial" style={{minHeight:"322px"}}>
                            <p>“ PTE Academic is all about mastering the tricks and algorithms which can guarantee you good score.

I am deeply satisfied from the quality of teaching that was provided by the institute that enabled me to achieve my desired score. ”</p>
                            <div class="coures_instractor_thumb">
                             
                                <div class="thumb_capstion">
                                    <h5><a href="#">Faizan Jamil</a></h5>
                                    <p>Former Student</p>
                                </div>
                            </div>
                        </div>
  </ReactBootstrap.Carousel.Item>
</ReactBootstrap.Carousel> 
              
            </div>
            <div class="text-center">
                    <a href="#/Testimonials" class="iq_link_1 ">More Reviews</a>
                </div>
            </div>
        </section>
        <div class="iq_contact_info" style={{background:"#fc9928"}}>
        <div class="container">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 text-center">
                        <p style={{color:"black",paddingTop:"10px",fontSize:"29px",fontWeight: "500"}}>Ready to achieve your target score?</p>
                        <p style={{color: "black",fontSize:"21px"}}>Book a free demo class today</p>
                    </div>
                    <div class="col-md-6 text-center" style={{top:"17px"}}>
                        <a href="#/Contact-us">
                        <div class="iq_link_1 special" style={{backgroundColor:"#054d9a!important"}}>Book an Appointment</div>
                            </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="iq_footer_bg">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-sm-6" style={{paddingRight:"0"}}>
                    <div class="iq_uni_title" style={{paddingRight: "20px"}}>
                        <h5 class="widget-title"><span>AEC PTE</span> Academy</h5>
                        <p style={{color:"white!important"}}>We are famous for our quality of education, delivery methods, and proven exam strategies. We prepare you for success, teach you all the technicalities of the PTE exam, and equip you with all the required skills and knowledge to excel in the test</p>
                        <ul>
                            <li><span>Moday - Friday</span> 10:00A.M - 08:00P.M</li>
                            <li><span>Saturday</span> Closed</li>
                            <li><span>Sunday</span> Closed</li>
                        </ul>
                    </div>
                </div>
             
                <div class="col-md-2 col-sm-6">
                    <div class="widget widget_archive">
                        <h5 class="widget-title"><span>Our</span> Services</h5>
                      
                        <ul>
                            <li><a style={{color:"white!important"}} href="#/Reading">Reading</a></li>
                            <li><a style={{color:"white!important"}} href="#/Writing">Writing</a> </li>
                            <li> <a style={{color:"white!important"}}href="#/Speaking">Speaking</a></li>
                            <li> <a style={{color:"white!important"}}href="#/Listening">Listening</a> </li>
                            {/* <li>
                                <a style={{color:"white!important"}} href="">Free Material</a>
                            </li> */}
                           
                        </ul>
                    </div>
                </div>
            
                <div class="col-md-3 col-sm-6">

                    <div class="widget widget_archive">
                        <h5 class="widget-title"><span>Quick</span> Links</h5>
                       
                        <ul>
                            <li>
                                <a style={{color:"white!important"}} href="#/Packages">Packages</a>
                            </li>
                            <li>
                                <a style={{color:"white!important"}} href="#/About-us">About Us</a>
                            </li>
                            <li>
                                <a style={{color:"white!important"}} href="">Blogs</a>
                            </li>
                            <li>
                                <a style={{color:"white!important"}} href="#/Gallery">Gallery</a>
                            </li>
                            
                            <li>
                                <a style={{color:"white!important"}} href="#/Testimonials">Testimonials</a>
                            </li>
                        </ul>
                    </div>

                </div>
            
                <div class="col-md-3 col-sm-6">
                    <div class="widget iq_footer_newsletter">

                        <h5 class="widget-title border-none"><span>Our</span> Connective</h5>
                        <ul class="iq_footer_social">
                            <li>
                                <a target="_blank" href="https://www.facebook.com/AECPTE/"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.instagram.com/aec_pte_academy/?igshid=mc29i900n7vh"><i class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://api.whatsapp.com/send?phone=+61481840167"><i class="fa fa-whatsapp"></i></a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
      </div>
    )
}
export default Bottom;