import React from 'react'
import Bottom from './Bottom'

function Contact(){
    return(
        <div>
            <title>Contact</title>
<div className="iner_banner">
    <div className="container">
        <h5>Contact Us</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Contact Us</a></li>
            </ul>
        </div>
    </div>
</div>


<div className="iq_wrapper">
    <div className="iqoniq2-contect-us">
        <div className="container">
            <div className="iqoniq2-section-map">
                <div className="iq_heading_1 text-center" style={{marginBottom:"0px",paddingBottom:"0px"}}>
                    <h4 style={{marginBottom:"0px"}}>Leave Us A <span>Message</span></h4>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{paddingTop: "116px"}}>
                        
                        <div className="widget iq_footer_newsletter">

                            <h5 className="widget-title border-none"><span>CONTACT </span> DETAIL</h5>
                            <ul>
                                <li style={{paddingBottom:"14px"}}>
                                    <a href="#" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/phone-call.png"} style={{width:"32px"}} /> <strong style={{paddingLeft:"10px"}}>+61 (3) 7014 6424</strong></a>
                                </li>
                                <li style={{paddingBottom:"14px"}}>
                                    <a href="#" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/email.png"} style={{width:"32px"}} /><strong style={{paddingLeft:"10px"}}>info@aecglobal.com.au</strong></a>
                                </li>
                                <li style={{paddingBottom:"14px"}}>
                                    <a href="#" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/place.png"} style={{width:"32px"}} /><strong style={{paddingLeft:"10px"}}>Suite 10, Level 7, 2 Queen   <span style={{marginLeft:"44px"}}> Street,VIC Melbourne 3000.</span> </strong></a>
                                </li>
                             
                            </ul>
                        </div>
                        <div className="widget iq_footer_newsletter">

                            <h5 className="widget-title border-none"><span>Our</span> Connected</h5>
                            <ul className="iq_footer_social">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/AECPTE/" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/facebook.png"} style={{width:"32px"}} /></a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/aec_pte_academy/?igshid=mc29i900n7vh" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/instagram.png"} style={{width:"32px"}} /></a>
                                </li>
                               
                                <li>
                                    <a target="_blank" href="https://api.whatsapp.com/send?phone=+61481840167" style={{border:"none"}}><img src={process.env.PUBLIC_URL+"images/logo/whatsapp.png"} style={{width:"32px"}}/></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="iqoniq2-leave-message">
                           
                            <iframe id="JotFormIFrame-210741348445858"
                                    title="PTE DEMO CLASS REGISTRATION"
                                    onload="window.parent.scrollTo(0,0)"
                                    allowtransparency="true"
                                    allowfullscreen="true"
                                    allow="geolocation; microphone; camera"
                                    src="https://form.jotform.com/210741348445858"
                                    frameborder="0"
                                    style={{minWidth:"100%",height:"539px",border:"none",scrolling:"no"}}></iframe>
                           
                        </div>
                    </div>
                </div>
            </div>
      
        </div>
    </div>
    
</div>
<div className="map-custom">
    <div id="map-canvas">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.7821952413997!2d144.9605370154119!3d-37.818570342091675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b3166baa79%3A0xe846f57879d9d74e!2sSuite%2010%2C%20Level%207%2F2%20Queen%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1599666715146!5m2!1sen!2sin" width="1700" height="350" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
</div>
<Bottom></Bottom>

        </div>
    )
}
export default Contact;