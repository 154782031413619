import React from 'react'
import Bottom from './Bottom'

function Packages(){
    return(
        <div>
            <title>Packages</title>
<div className="iner_banner course-single">
    <div className="container">
        <h5>Packages</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Packages</a></li>
            </ul>
        </div>
    </div>
</div>
<div className="packageContainer">
    <div className="package">
        <div className="packageLeftBar">
            <ul>
                <li>Individual Package Details</li>
                <li> Price</li>
                <li> Duration</li>
                <li>
                    Study Plan
                </li>
                <li>
                    Offline Mock Test
                </li>
                <li>
                    Practice Workbooks
                </li>
                <li>Updated Material bank</li>
                <li>Offline Mock Exam</li>
                <li>Speaking Feedback</li>
                <li>Writing Feedback</li>
                <li>1:1 Consultation</li>
                <li></li>
            </ul>
        </div>
        <div className="packageDetails yellow">
            <ul>
                <li><span>Basic</span></li>
                <li>$160</li>
                <li>1 Week</li>
                <li><i className="fa fa-times"></i></li>
                <li>2 Only</li>
                <li><i className="fa fa-times"></i></li>
                <li>Conditioned</li>
                <li><i className="fa fa-times"></i></li>
                <li><i>1</i></li>
                <li><i>1</i></li>
                <li><i className="fa fa-times"></i></li>
                <li>
                    <a className="signUp" href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">Sign Up</a>
                </li>
            </ul>
        </div>
        <div className="packageDetails orange">
            <ul>
                <li><span>Bronze</span></li>
                <li>$300</li>
                <li>3 Weeks</li>
                <li><i className="fa fa-times"></i></li>
                <li>4 Only</li>
                <li>Conditioned</li>
                <li>Conditioned</li>
                <li><i className="fa fa-times"></i></li>
                <li>2</li>
                <li>2</li>
                <li>1</li>
                <li>
                    <a className="signUp" href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">Sign Up</a>
                </li>
            </ul>
        </div>

        <div className="packageDetails red">
            <ul>
                <li><span>Silver</span></li>
                <li>$499</li>
                <li>6 Weeks</li>
                <li>2</li>
                <li>6</li>
                <li>2 Months</li>
                <li>2 Months</li>
               
                <li><i className="fa fa-times"></i></li>
                <li>Once a week</li>
                <li>Once a week</li>
                <li><i className="fa fa-check"></i></li>
                <li>
                    <a className="signUp" href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">Sign Up</a>
                </li>
            </ul>
        </div>
        <div className="packageDetails maroon">
            <ul>
                <li><span>Gold</span></li>
                <li>$650</li>
                <li>1 Year</li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li>1</li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><a className="signUp" href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">Sign Up</a></li>
            </ul>
        </div>
        <div className="packageDetails maroon brown">
            <ul>
                <li><span>Guaranteed</span></li>
                <li>$999</li>
                <li style={{padding: "5px 15px"}}>Until the score is achieved</li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li>1</li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li><i className="fa fa-check"></i></li>
                <li>
                    <a className="signUp" href="javascript:void(
        window.open(
          'https://form.jotform.com/210741348445858',
          'blank',
          'scrollbars=yes,
          toolbar=no,
          width=700,
          height=500'
        )
      )
    ">Sign Up</a>
                </li>
            </ul>
        </div>
    </div>

</div>
<Bottom></Bottom>

        </div>
    )
}
export default Packages;