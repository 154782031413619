import react from 'react';
import Bottom from './Bottom'


function Speaking() {
    return (
     <div>
         <title>Speaking</title>
<div className="iner_banner course-single">
    <div className="container">
        <h5>Speaking</h5>
        <div className="banner_iner_capstion">
            <ul>
                <li><a href="#/Home">Home</a></li>
                <li><a href="#">Speaking</a></li>
            </ul>
        </div>
    </div>
</div>

<div className="iq_content_wrap">
    <section className="coures_single_page">
        <div className="container">
            <div className="row">
                <div className="col-md-12" style={{background: "#f6f6f6!important"}}>
                    <div className="course_single_heading">
                 
                    </div>
                
                    <div className="about_courses">
                        <div className="about_courses_thumb">
                            <section className="speaking">
                                <div className="col-md-6 col-sm-offset-6 col-md-offset-6">
                                    <div className="iq_knowledge_des" style={{padding:"0"}}>
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE SPEAKING <span> SECTION</span> </h4>
                                        </div>
                                        <p>Part 1 – Section 1 of PTE-A test consists of Speaking module and completes approximately within 30-35 minutes. There are 5 tasks in total which also contribute marks in other modules such as Reading and Listening. All the tasks are recorded in the system itself within a given timeframe. This section mainly assesses candidates on their abilities to speak fluently with correct pronunciation. </p>
                                    </div>
                                </div>
                            </section>


                            <div id="my-tab-content" className="tab-content">
                                <div className="tab-pane active" id="description">
                                    <div className="about_courses_thumb_capstion">
                                        <div className="iq_heading_1 text-left">
                                            <h4>PTE SPEAKING  <span> OVERVIEW</span></h4>
                                        </div>
                                        <br></br>
                                     <p><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>1.</span>Personal Introduction </span><br></br><br></br>PTE Speaking Module starts with a Personal Introduction task where the test taker records a brief personal introduction within 30 seconds. Before you record you response, you will have 25 seconds to prepare – you can talk about your interest, your hobbies, your future plans and so on. This task does not contribute marks in the final score but will be sent together with your score card to the institutions selected by you.</p>
                                         <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>2.</span>Read Aloud </span><br></br><br></br>Read Aloud is the very first task of Speaking module in PTE-A exam. In this task type, a small passage approximately 50-60 words long appears on the screen and the candidates reads text aloud. There are 6-7 questions for this task type and the test taker has 30-40 seconds to prepare, depending on the length of the passage. After a short beep, start speaking immediately and record your response within given time which varies and depends on the length of the text. You are only able to record your response once.</p>
                                         <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>3.</span>Repeat Sentence  </span><br></br><br></br>In this task type, the candidate hears a recording of a sentence – repeat the sentence exactly as you hear it. There are 10-12 questions for this task and after 3 seconds gap, the audio begins automatically. Listen to the audio (1 sentence) carefully and repeat the sentence when the microphone opens. You will have 15 seconds to give your response. The recording will be played only once.</p>
                                         <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>4.</span>Describe Image  </span><br></br><br></br>In this task, an image appears on the screen – describe it in detail within 40 seconds. You will encounter with different types of images such as graphs, charts, pictures, tables, maps, layouts, processes, life cycles and so on. There are 6-7 questions for this task which test your ability of how well you describe the key points of the given image. You will have 25 seconds to prepare your answers and after a short beep, start speaking instantly. You must describe the given image in as much detail as possible. You are only able to record your response once.</p>
                                         <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>5.</span>Re-Tell Lecture </span><br></br><br></br>For this task type, the test taker listens/watches an audio/video nearly 60-90 seconds long. After listening to the audio, please speak into the microphone and re-tell the given lecture in your own words. After 3 seconds, the audio/video begins to play automatically. After the 10 seconds gap, you will hear a beep – now record your answer within 40 seconds. There will be 3-4 questions of this task and your answers are judged on how well you cover all the key ideas presented in the lecture. You will be able to record your response only once so complete your answer before the progress bar reaches the end. </p>
                                         <p style={{paddingTop:"24px"}}><span style={{fontSize:"28px"}}><span style={{marginRight:"5px"}}>6.</span>Answer Short Question</span><br></br><br></br>Answer Short Question is the 5th and last task of PTE Speaking module. In this task, you will hear a question – answer with a single word or a few words. There will be 10-12 questions in this task and at times, they will come with an image on the screen. You are supposed to give an answer within 3 seconds clearly and loudly. The audio begins to play automatically – after listening to the speak into the microphone promptly as there is no short beep. Make sure you give your response before the progress bar reaches the end. These questions are based on academic general knowledge and contribute score towards your vocabulary, PTE listening, and PTE speaking sections.</p>
                                        
                                      

                                        <div className="border-div"></div>
                                   
                                    </div>
                               
                                    <div className="iq_heading_1 text-left">
                                        <h4>REQUIRED  <span> SKILLS</span> </h4>
                                    </div>
                                    <p>To ace in PTE Speaking module, you need to speak with appropriate intonation and pronunciation, while focusing on the content in general. </p>

                                    <br></br><br></br>
                                            <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="iq_teacher_style_1" style={{minHeight:"238px"}}>
                                                        <div className="text">
                                                            <img src={process.env.PUBLIC_URL+"images/icons/check.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                            <br></br>

                                                            <h4><a href="#">Content</a></h4>
                                                            <p>The test taker understands the written content and organises the key points for better understanding.  </p>
                                                        </div>

                                                    </div>
                                                </div>
                                           
                                                <div className="col-md-4 col-sm-6">
                                                    <div className="iq_teacher_style_1" style={{minHeight:"238px"}}>
                                                        <div className="text">
                                                            <img src={process.env.PUBLIC_URL+"images/icons/check1.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                            <br></br>

                                                            <h4><a href="#">Oral Fluency</a></h4>
                                                            <p>The speaker must speak smoothly with correct intonation, emphasis in a natural tone and accent.</p>                                                         
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-4 hidden-sm">
                                                    <div className="iq_teacher_style_1" style={{minHeight:"238px"}}>
                                                     
                                                        <div className="text">
                                                            <img src={process.env.PUBLIC_URL+"images/icons/check2.png"} style={{paddingBottom:"25px",width:"70px"}}></img>

                                                           <br></br>

                                                            <h4><a href="#">Pronunciation</a></h4>
                                                            <p>The speaker knows how to pronounce words and phrases correctly with appropriate stress.  </p>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="border-div"></div>
                                                <div className="iq_heading_1 text-left">
                                                    <h4>SCORING <span>  OVERVIEW </span></h4>
                                                    <p>All tasks in PTE Speaking test is marked by artificial intelligence (AI). For PTE Speaking Test score is based on multiple key criteria that need to be filled to achieve high score.  </p>
                                                </div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"237px"}}> Task Type </th>
                                                            <th style={{width:"237px"}}> Scoring 	 </th>
                                                            <th> Communicative Skills Assessed </th>
                                                            <th style={{width:"290px"}}> Enabling Skills  </th></tr>
                                                       
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td> Read Aloud	</td>
                                                            <td>Partial Credit </td>
                                                            <td>Speaking <br></br> Reading</td>
                                                            <td> Oral Fluency<br></br>Pronunciation<br></br>
Content

                                                        </td>    </tr>
                                                        
                                                   
                                                        <tr>
                                                            <td> Repeat Sentence</td>
                                                            <td> Partial Credit</td>
                                                            <td> Speaking<br></br>
Listening
</td>
                                                            <td> Oral Fluency<br></br>Pronunciation<br></br>
Content
</td>

                                                        </tr>
                                                        <tr>
                                                            <td> Describe Image	</td>
                                                            <td> Partial Credit</td>
                                                            <td> Speaking</td>
                                                            <td> Oral Fluency<br></br>
Pronunciation<br></br>
Content
</td>

                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>Re-tell Lecture</td>
                                                            <td>Partial Credit</td>
                                                            <td>Speaking<br></br>
Listening
</td>
                                                            <td>Oral Fluency<br></br>
Pronunciation<br></br>
Content
</td>

                                                        </tr>
                                                        <tr>
                                                            <td> Answer Question</td>
                                                            <td> 	Correct/Incorrect</td>
                                                            <td> 	Speaking<br></br>
Listening
</td>
                                                            <td> Vocabulary     	</td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                   
                                                </table>
                                             
                                                </div>
                                   
                                    <div className="border-div" style={{paddingTop:"40px",marginBottom: "-9px"}}></div>


                                    <div className="iq_heading_1 text-left" style={{paddingTop: "55px"}} >
                                        <h4>EXPERT TIPS FOR  <span>79+ IN PTE SPEAKING  </span></h4>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6">
                                            <ul className="categries-list">
                                                <li><a href="#">Speak clearly in a natural tone and pace. No need to rush.</a></li>
                                                <li><a href="#">Avoid irrelevant pauses and filler sounds like hmmms  aaa.</a></li>
                                                <li><a href="#">Use punctuation to help you decide where to pause when you read</a></li>
                                                <li><a href="#">Make best use of countdown time and prepare your answer well.</a></li>
                                                <li><a href="#">Provide all the key elements from the image in your answer</a></li>
                                               
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="border-div"></div>


                            </div>
                        </div>
                    </div>
                
                </div>

            </div>
        </div>
    </section>
</div>
<Bottom></Bottom>

</div>

)
};

export default Speaking;